:root {
  --color-black: #000000;
  --color-white: #ffffff;

  --font-titles: "gobold_bolditalic";
  --font-body: "codec_proregular";
  --font-body-bold: "codec_probold";
  --font-body-extraBold: "codec_proextrabold";
  --font-body-heavy: "codec_proheavy";

  /* Modal */
  --modal-bg: #010512;

  /* Custom Colors */
  --primary-color: rgb(0, 140, 255);
  --primary-color-emphasis: #ff5f1f;
  --primary-color-dark: #008cff;
  --secondary-color: #1e2e50;
  --secondary-color-emphasis: #1e2e50;
  --secondary-color-emphasis-rgb: rgb(62, 75, 90);
  --accent-color: #008cff;
  --dashboard-bg: #101d3b;
  --body-color: #aaaaaa;
  --dark-color: #0c1734;
  --info-color: #009cde;
  --gray-color: #a6a6a6;
  --golden-color: #c5a260;
  --golden-color-emphasis: #cc9747;
  --primary-color-light: #192744;
  --dark-border: #13181e;
  --dark-popup: #010922;
  --grey-color: #4C555F;
  --dark-box:#181619;
  --package1: #6C7FB2;
  --package2: #00A8E9;
  --package3: #ff5f1f;

  /* Bootstrap Variable Override */
  --bs-primary-rgb: var(--primary-color);
  --bs-secondary-rgb: var(--secondary-color);
  --bs-link-color: var(--primary-color);
  --bs-secondary-bg-subtle: #314a81;
  --bs-success-rgb: 7, 188, 12;
  --disabled: #3e4b5a79;
  --navbar-top-height: 5.25rem;
}
