:root {
	--color-black: #000000;
	--color-white: #ffffff;
    /* --font-titles: "gobold_bolditalic";
    --font-body: "codec_proregular";
    --font-body-bold: "codec_probold";
    --font-body-extraBold: "codec_proextrabold";
    --font-body-heavy: "codec_proheavy"; */
    /* Modal */
	--modal-bg: #010512;
    /* Custom Colors */
	--primary-color1: rgb(183, 0, 255);
	--primary-color1-emphasis: #cc4dff;
	--primary-color1-dark: #662680;
	--secondary-color1: rgb(62, 75, 90);
	--secondary-color1-emphasis: #3e4b5a;
	--secondary-color1-emphasis-rgb: 62, 75, 90;
	--accent-color1: #ff9933;
	--dashboard-bg1: #13181e;
	--body-color1: #aaaaaa;
	--dark-color1: #181619;
	--info-color1: #00c2ff;
	--gray-color1: #a6a6a6;
	--golden-color1: #c5a260;
	--golden-color1-emphasis: #cc9747;
    /* Bootstrap Variable Override */
	--bs-primary-rgb1: var(--primary-color1);
	--bs-secondary1-rgb1: var(--secondary-color1);
	--bs-link-color1: var(--primary-color1);
	--bs-secondary1-bg-subtle: #323d48;
	--bs-success-rgb: 7, 188, 12;
	--navbar-top-height1: 5.25rem;
}

@media screen and (min-width: 1400px) {
	.col-xxl-five {
		flex: 0 0 auto;
		width: 20%;
	}
}

@media screen and (min-width: 1200px) {
	.col-xl-five {
		flex: 0 0 auto;
		width: 20%;
	}
}

.landing-authModule .loader-main {
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 1050;
	position: fixed;
	left: 0;
	top: 0;
}

.landing-authModule .spinner {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 999;
	width: 80px;
	height: 80px;
	margin: -40px 0 0 -40px;
	border: 4px solid #f3f3f3;
	border-radius: 50%;
	border-top: 4px solid var(--primary-color1);
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.landing-authModule .subscribe_input .form-control {
	background-color: #170020;
    /* border:var(--color-white) !important ; */
	border-radius: 0px;
	color: var(--color-white);
	padding: 0 1.25rem;
	font-size: 0.875rem;
	height: 3.75rem;
	border: 1px solid var(--color-white);
}

.landing-authModule textarea.form-control {
	height: 16.25rem;
	padding: 1.25rem;
	background-color: transparent;
	border: 1px solid var(--secondary-color1-emphasis);
	resize: none;
}

.landing-authModulem .subscribe_input .form-control:focus {
	background-color: #170020;
	color: var(--color-white);
	outline: none;
	box-shadow: none;
	border-color: var(--secondary-color1-emphasis);
}

.landing-authModule .subscribe_input .form-control-sm {
	height: 2.5rem;
}

.landing-authModule .subscribe_input .form-control-xs {
	height: 1.25rem;
}

.landing-authModule .subscribe_input .form-control::-webkit-input-placeholder {
	color: var(--color-white) !important;
}

.landing-authModule .subscribe_input .form-control::-moz-placeholder {
	color: var(--color-white) !important;
}

.landing-authModule .subscribe_input .form-control:-ms-input-placeholder {
	color: var(--color-white) !important;
}

.landing-authModule .subscribe_input .form-control:-moz-placeholder {
	color: var(--color-white) !important;
}

@media (min-width: 1900px) {
	.container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
		max-width: 1744px;
	}
}

.landing-authModule .bg-primary-dark {
	background-color: var(--primary-color1-dark) !important;
}

.landing-authModule .bg-info {
	background-color: var(--info-color1) !important;
}

.landing-authModule .bg-dark {
	background-color: var(--dashboard-bg) !important;
}

.landing-authModule .bg-warning {
	background-color: var(--accent-color) !important;
}

.landing-authModule .text-primary {
	color: var(--primary-color1) !important;
}

.landing-authModule .text-warning {
	color: var(--accent-color) !important;
}

.landing-authModule .text-body {
	color: var(--body-color1) !important;
}

.landing-authModule .text-golden {
	color: var(--golden-color1) !important;
}

.landing-authModule .progress {
	--bs-progress-bg: var(--gray-color1);
	--bs-progress-height: 0.5rem;
}

.landing-authModule .icon-16 {
	width: 1.25rem;
}

.landing-authModule .icon-20 {
	width: 1.25rem;
}

.landing-authModule .icon-24 {
	width: 1.5rem;
}

.landing-authModule .icon-30 {
	width: 1.875rem;
}

.landing-authModule .icon-50 {
	width: 3.125rem;
}

.landing-authModule .icon-65 {
	width: 4.0625rem;
}

.landing-authModule .img-thumbnail-36 {
	width: 2.25rem;
	height: 2.25rem;
	padding: 0;
	border-radius: 50%;
	object-fit: cover;
}

.landing-authModule .img-thumbnail-65 {
	width: 4.0625rem;
	height: 4.0625rem;
	padding: 0;
	border-radius: 50%;
	object-fit: cover;
}

.h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
	font-weight: normal;
}

.fs-3 {
	font-size: 1.25rem !important;
}

.fs-4 {
	font-size: 1.125rem !important;
}

.fs-5 {
	font-size: 0.875rem !important;
}

.fs-6 {
	font-size: 0.75rem !important;
}

.fs-7 {
	font-size: 0.625rem !important;
}

  /* @media screen and (min-width: 992px) {
    .mw-lg-20 {
      max-width: 20%;
    }
  } */

.landing-authModule .border-gray-50 {
	border-color: rgb(112, 112, 112, 0.5) !important;
}

.landing-authModule .list-group-flush {
	--bs-list-group-bg: transparent;
	--bs-list-group-color: var(--color-white);
}

.landing-authModule .btn1 {
	text-transform: uppercase;
	border-radius: 4px;
	font-size: 1.25rem;
	font-family: var(--font-body-bold);
	font-weight: normal;
	letter-spacing: 0.1px;
	padding: 0 1.25rem;
	height: 3.5625rem;
	line-height: 3.4375rem;
	transition: all 0.3s;
}

.landing-authModule .btn1-sm {
	height: 2.5rem;
	font-size: 0.875rem;
	line-height: 2.5rem;
}

.landing-authModule .btn1-xs {
	height: 1.875rem;
	font-size: 1rem;
	line-height: 1.875rem;
}

.landing-authModule .btn1-arrow {
	background-image: url(./images/btn-arrow-svg.svg);
	background-position: right 20px center;
	background-repeat: no-repeat;
	text-align: left;
}

.landing-authModule .btn1-arrow:hover {
	background-position: right 12px center;
}

.landing-authModule .btn1-primary {
	--bs-btn1-bg: transparent;
	--bs-btn1-border-color: var(--secondary-color1);
	--bs-btn1-hover-bg: transparent;
	--bs-btn1-hover-border-color: var(--secondary-color1);
	--bs-btn1-active-bg: transparent;
	--bs-btn1-active-border-color: transparent;
	--bs-btn1-disabled-color: #fff;
	--bs-btn1-disabled-bg: var(--info-color1);
	--bs-btn1-disabled-border-color: var(--info-color1);
}

.landing-authModule .btn1-primary:focus {
	color: var(--color-white);
}

.landing-authModule .btn1-secondary {
	--bs-btn1-bg: var(--secondary-color1-emphasis);
	--bs-btn1-border-color: var(--secondary-color1-emphasis);
	--bs-btn1-hover-bg: transparent;
	--bs-btn1-hover-border-color: var(--secondary-color1-emphasis);
}

.landing-authModule .btn1-golden {
	--bs-btn1-color: var(--color-white);
	--bs-btn1-bg: var(--golden-color1-emphasis);
	--bs-btn1-border-color: var(--golden-color1-emphasis);
	--bs-btn1-hover-bg: transparent;
	--bs-btn1-hover-border-color: var(--golden-color1-emphasis);
	--bs-btn1-active-bg: var(--golden-color1-emphasis);
	--bs-btn1-active-border-color: var(--golden-color1-emphasis);
	--bs-btn1-disabled-color: #fff;
	--bs-btn1-disabled-bg: var(--golden-color1-emphasis);
	--bs-btn1-disabled-border-color: var(--golden-color1-emphasis);
	--bs-btn1-border-width: 1px;
	border-style: solid;
}

.landing-authModule .btn1-golden:focus {
	color: var(--color-white);
}

.landing-authModule .btn1-gradient {
	background: linear-gradient(90deg, #cfb279 0%, #f1dcb1 54.17%, #b39559 100%);
	--bs-btn1-border-color: var(--secondary-color1-emphasis);
	--bs-btn1-hover-bg: transparent;
	--bs-btn1-hover-border-color: var(--white-color);
}

.landing-authModule .btn1-gradient:hover {
	background: transparent;
}

.landing-authModule .btn1-outline-golden {
	--bs-btn1-color: var(--color-white);
	background: transparent;
	--bs-btn1-border-color: var(--body-color1);
	--bs-btn1-hover-bg: var(--color-white);
	--bs-btn1-hover-border-color: var(--color-white);
}

.landing-authModule .btn1-outline-golden:hover {
	color: var(--bs-secondary1-bg-subtle);
}

.landing-authModule .btn1-outline-primary {
	--bs-btn1-color: var(--primary-color1);
	--bs-btn1-border-color: var(--primary-color1);
	--bs-btn1-hover-color: var(--color-white);
	--bs-btn1-hover-bg: var(--primary-color1);
	--bs-btn1-hover-border-color: var(--primary-color1);
	--bs-btn1-active-color: var(--color-white);
	--bs-btn1-active-bg: var(--primary-color1);
	--bs-btn1-active-border-color: var(--primary-color1);
	--bs-btn1-active-shadow: none;
	--bs-btn1-disabled-color: var(--primary-color1);
	--bs-btn1-disabled-bg: transparent;
	--bs-btn1-disabled-border-color: var(--primary-color1);
}
  /* 
  h1,
  .h1 {
    font-size: 3.375rem;
    line-height: 1.3;
    font-family: var(--font-titles);
  }

  h3,
  .h3 {
    font-size: 2rem;
    line-height: 1.111;
    font-weight: 400;
  }

  h4,
  .h4 {
    font-size: 1.5rem;
    line-height: 1;
  }

  h5,
  .h5 {
    font-size: 1.25rem;
    line-height: 1;
  }

  h6,
  .h6 {
    font-size: 1rem;
    line-height: 1;
    font-family: var(--font-body-bold);
  } */

.title h1,
  .title h2,
  .title h3,
  .title h4,
  .title h5,
  .title h6 {
	font-family: var(--font-titles);
	font-weight: normal;
	margin: 0;
}

.heading-primary {
	font-family: var(--font-titles);
}

.title .titleIcon img {
	max-width: 2.5rem;
}

.modal {
	--bs-modal-zindex: 1050;
	--bs-modal-bg: var(--dark-popup);
	--bs-modal-padding: 3.125rem;
	--bs-modal-width: 62.5rem;
	--bs-modal-border-radius: 20px;
	--bs-modal-border-width: 0;
}

.modal-backdrop {
	--bs-backdrop-opacity: 0.4;
}

.modal .btn1-close {
	--bs-btn1-close-bg: url(./images/cross-circle-svg.svg);
	--bs-btn1-close-opacity: 1;
	background-color: var(--color-white);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 0;
	margin: 0;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	position: absolute;
	right: 1.875rem;
	top: 1.875rem;
}

  /**************************/
  /* FOOTER */
  /**************************/

.footer {
	padding: 6.8rem 0 0 0;
    /* border-top: 1px solid #eee; */
    /* background-color: #000000; */;
}

.logo-col {
	display: flex;
	flex-direction: column;
}

.footer-logo {
	display: block;
}

.social-links {
	list-style: none;
	display: flex;
	gap: 0.4rem;
	margin-left: -2rem;
}

.social-icon {
	height: 2.4rem;
	width: 2.4rem;
}

.copyright {
	font-size: 1.4rem;
	line-height: 1.6;
	color: #fff;
}

.footer-heading {
	font-size: 2.8rem;
	font-weight: 500;
	color: #fff;
}

.contacts {
	font-style: normal;
	font-size: 1.6rem;
	line-height: 1.6;
}

.footer-nav {
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
	margin-left: -2rem;
}

.footer-link:link,
  .footer-link:visited {
	text-decoration: none;
	font-size: 1rem;
	color: #f3f3f3;
	transition: all 0.5s;
}

.footer-nav .active {
	color: #1babf9;
}

.footer-link:hover,
  .footer-link:active {
	color: #1babf9;
}

.section-connect {
	background-color: #000000;
	padding: 1rem 0 1rem 0rem;
}

.hover-fx {
	font-size: 16px;
	display: inline-block;
	cursor: pointer;
	width: 26px;
	height: 26px;
	border-radius: 50%;
	text-align: center;
	position: relative;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 300ms;
}

.hover-fx:after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: "";
	box-sizing: content-box;
	box-shadow: 0 0 0 3px #1babf9;
	top: 0;
	left: 0;
	opacity: 0;
	transition: 300ms;
}

.hover-fx:hover {
	color: #1babf9;
}

.hover-fx:hover:after {
	opacity: 1;
	transform: scale(1.15);
}

.section-subscribe {
	background: #170020;
	padding: 0rem;
	border: transparent;
}

.landing-authModule .nav-link a {
	display: flex;
	align-items: center;
}

.section-how .card {
	--bs-card-spacer-y: 1rem;
	--bs-card-spacer-x: 1rem;
	--bs-card-title-spacer-y: 0.5rem;
	--bs-card-title-color: ;
 
	--bs-card-border-width: var(--bs-border-width);
	--bs-card-border-color: transparent;
	--bs-card-border-radius: var(--bs-border-radius);
	--bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(
      var(--bs-border-radius) - (var(--bs-border-width))
    );
	--bs-card-cap-padding-y: 0.5rem;
	--bs-card-cap-padding-x: 1rem;
	--bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
	--bs-card-cap-color: ;
  
	--bs-card-color: ;
    --bs-card-bg: var(--bs-body-bg);
	--bs-card-img-overlay-padding: 1rem;
	--bs-card-group-margin: 0.75rem;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	height: var(--bs-card-height);
	color: var(--bs-body-color);
	word-wrap: break-word;
	background-color: var(--bs-card-bg);
	background-clip: border-box;
	border: transparent !important;
	border-radius: var(--bs-card-border-radius);
}

.landing-authModule .card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	color: #f3f3f3;
	bottom: 0;
	left: 0;
	padding: 0 !important;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

  html[dir='rtl'] .landing-authModule .card-img-overlay {
    align-items: flex-start;
  }

  html[dir='rtl'] .landing-row-rtl {
    flex-direction: row;
  }

  html[dir='rtl'] .landing-rtl {
    direction: rtl;
  }

  /*  */
.landing-authModule .header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f3f3f3;
    /* Because we want header to be sticky later */
	height: 4.6rem;
	position: relative;
	width: 100%;
	z-index: 1000;
}

.landing-authModule .grid--box-cols {
	grid-template-columns: repeat(2, 1fr);
}

.landing-authModule .grid--3-cols {
	grid-template-columns: repeat(3, 1fr);
}

.landing-authModule .grid--4-cols {
	grid-template-columns: repeat(4, 1fr);
}

.landing-authModule .section-work {
    /* background:url(./images/page-bg.jpg) ; */
	min-height: auto;
}

.landing-authModule .logo {
	height: 2.2rem;
}

  /**************************/
  /* NAVIGATION */
  /**************************/

.landing-authModule .navbar-nav .nav-link.active,
  .landing-authModule .navbar-nav .nav-link.show {
	color: var(--color-white);
}

.landing-authModule .navbar-nav .nav-link.active,
.landing-authModule .navbar-nav .nav-link.show::after {
	color: #008cff;
	border-bottom: 2px solid #008cff;
}

.landing-authModule .navbar-nav li {
	margin-left: 1rem;
}

.landing-authModule .nav-link {
	display: block;
	padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
	font-size: var(--bs-nav-link-font-size);
	font-weight: var(--bs-nav-link-font-weight);
	color: var(--color-white);
	text-decoration: none;
	margin-left: 1.5rem;
	background: 0 0;
	border: 0;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
}

.landing-authModule .nav-link:focus,
.landing-authModule .nav-link:hover {
	color: #008cff;
	border-bottom: 2px solid #008cff;
}

.landing-authModule .navbar-toggler {
	padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
	font-size: var(--bs-navbar-toggler-font-size);
	line-height: 1;
	color: var(--bs-navbar-color);
	background-color: var(--color-white);
	border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
	border-radius: var(--bs-navbar-toggler-border-radius);
	transition: var(--bs-navbar-toggler-transition);
}

  /* MOBILE */
.btn1-mobile-nav {
	border: none;
	background: none;
	cursor: pointer;
	display: none;
}

.landing-authModule .icon-mobile-nav {
	height: 4.8rem;
	width: 4.8rem;
	color: #f3f3f3;
}

.icon-mobile-nav[name="close-outline"] {
	display: none;
}

.blue-line,
  .top-and-bottom {
	position: relative;
}




.blue-line::after {
	content: "";
	position: absolute;
	border: 1px solid #00c2ff;
	background: rgba(255, 255, 255, 0);
	box-shadow: 0px 2px 4px 0px #00c2ff, 0px -2px 4px 0px #00c2ff,
      0px -4px 4px 0px rgba(0, 194, 255, 0.5),
      0px 4px 4px 0px rgba(0, 194, 255, 0.5);
	width: 100%;
	height: 5px;
	bottom: -1px;
	left: 0;
	z-index: 1;
}

.top-and-bottom::after {
	content: "";
	position: absolute;
	border: 1px solid #000;
	background: rgba(255, 255, 255, 0);
	box-shadow: 0px 2px 4px 0px #fff, 0px -2px 4px 0px #fff,
    0px -4px 4px 0px rgba(255, 255, 255, 0),
    0px 4px 4px 0px rgba(255, 255, 255, 0);
	width: 100%;
	height: 3px;
	bottom: -10px;
	left: 0;
	margin: 0px;
	z-index: 2;
}

.landing-authModule.title_secondary {
	font-family: var(--font-titles);
}

.sticky.is-sticky {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 1000;
	width: 100%;
}

.landing-authModule .input-group-text {
	display: flex;
	align-items: center;
	padding: 7px 15px;
	font-size: 0.6rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--bs-body-color);
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
	background-color: var(--bs-tertiary-bg);
	border: var(--bs-border-width) solid var(--bs-border-color);
	border-radius: 0px;
}

.landing-authModule .subheading {
	font-family: var(--font-body);
	font-size: 16px;
	color: #686868;
}

.landing-authModule ul.social-links {
	display: flex;
}

.landing-authModule ul.social-links li {
	list-style: none;
    /* margin: 0 5px; */
	height: 40px;
	width: 40px;
	color: #f3f3f3;
	border: 2px solid #00c2ff;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	transition: all ease 0.3s;
}

.landing-authModule ul.social-links li:hover {
	color: #f3f3f3;
	background: #00c2ff;
	cursor: pointer;
}

.landing-authModule .connect-card .card {
	--bs-card-spacer-y: 1rem;
	--bs-card-spacer-x: 1rem;
	--bs-card-title-spacer-y: 0.5rem;
	--bs-card-title-color: ;
  
	--bs-card-border-width: var(--bs-border-width);
	--bs-card-border-color: var(--bs-border-color-translucent);
	--bs-card-border-radius: var(--bs-border-radius);
	--bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(
      var(--bs-border-radius) - (var(--bs-border-width))
    );
	--bs-card-cap-padding-y: 0.5rem;
	--bs-card-cap-padding-x: 1rem;
	--bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
	--bs-card-cap-color: ;
    
	--bs-card-color: ;
    --bs-card-bg: transparent;
	--bs-card-img-overlay-padding: 1rem;
	--bs-card-group-margin: 0.75rem;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	height: var(--bs-card-height);
	color: var(--bs-body-color);
	word-wrap: break-word;
    /* background-color: var(--bs-card-bg); */
	background-clip: border-box;
	border: var(--bs-card-border-width) solid var(--bs-card-border-color);
	border-radius: var(--bs-card-border-radius);
}

  /* Navbar */

.landing-authmodule header {
	width: 100%;
	height: 100vh;
    /* background: url(hero.jpg) no-repeat 50% 50%; */
	background-size: cover;
}

.landing-authModule .logo {
	line-height: 60px;
	position: fixed;
	float: left;
	margin: 16px 46px;
	color: #fff;
	font-weight: bold;
	font-size: 20px;
	letter-spacing: 2px;
}

.landing-authmodule .navbar {
	background-color: #000;
	height: auto;
}

.card-img-player {
	position: absolute;
	height: auto;
	width: 55%;
	top: 4rem;
	right: 0;
}
html[dir="rtl"] .card-img-player {
  transform: translateX(-100%);
}
.landing-authModule .navbar-collapse {
	flex-basis: 100%;
	flex-grow: 1;
	align-items: center;
	background: #000;
}

.landing-authModule .navbar-logo {
	height: auto;
	width: 15rem;
}

.dropdown-menu {
    background-color: rgb(0, 0, 0);
    color: var(--color-white);
    margin-top: 1rem;
    border-radius: 0;
    box-shadow: none;
    padding: 0.625rem 0;
    left: inherit;
    right: 0;
  }
  html[dir="rtl"] .customDropdown .dropdown-menu {
    right: inherit;
  }
  html[dir="rtl"] .customDropdown {
    margin-left: 2rem;
  }
.customDropdown .dropdown-menu .dropdown-item {
  color: var(--color-white);
  padding: 0.4375rem 1.25rem;
}

.headerDropDown .dropdown-menu .dropdown-item:hover {
	color: var(--bs-dropdown-link-active-bg) !important;
    background-color: #000 !important;
}

  /* PAGE _2 */

.landing-authModule .section-explore {
	padding: 6rem 0;
}

.landing-authModule .explore-text-area h1 span {
	background: linear-gradient(75deg, #be38d3 0%, #00a2ff 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	padding: 1rem;
}

.landing-authModule .grid-img {
	height: auto;
	width: 100%;
}

.landing-authmodule main {
	position: relative;
	z-index: 2;
	margin-top: 3.4rem;
}

.landing-authModule .main-bg {
    /* background: url("../images/page-bg.jpg"); */
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
}

.landing-authModule .submit-form .form-control {
	background-color: transparent;
	border: transparent;
	border-bottom: 1px solid #f3f3f3 !important;
	border-radius: 0px;
	color: var(--color-white);
	padding: 0 1.25rem;
	font-size: 0.875rem;
	height: 3.75rem;
}

.landing-authModule textarea.form-control {
	height: 16.25rem;
	padding: 1.25rem;
	background-color: transparent;
	border: none;
	resize: none;
}

.landing-authModule .box-card {
	padding: 64px 0;
}

.landing-authModule .btn1-submit {
	--bs-btn1-bg: #fff;
	border-radius: 0;
	--bs-btn1-border-color: transparent;
	--bs-btn1-hover-bg: #f3f3f3;
	--bs-btn1-hover-border-color: #f3f3f3;
	--bs-btn1-active-bg: transparent;
	--bs-btn1-active-border-color: transparent;
	--bs-btn1-disabled-color: #fff;
	--bs-btn1-disabled-bg: var(--info-color1);
	--bs-btn1-disabled-border-color: var(--info-color1);
}

.sectionPartner {
	padding: 2rem 6rem 2rem 6rem;
}

.square-holder {
	align-items: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
	border-radius: 8px;
	background-color: #262626;
	height: 220px;
	width: 100%;
}

.square-holder img {
	width: 55%;
	height: auto;
	transition: all 0.3s;
}

.square-holder:hover img {
	filter: none;
}

.headTexture {
	background: linear-gradient(75deg, #be38d3 0%, #00a2ff 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	padding: 1rem;
}

.hero-area {
	min-height: 600px;
	display: flex;
	align-items: center;
}

.hero_section {
	position: relative;
	margin-top: 78px;
	min-height: 600px;
	background-image: url(../LandingPage/images/Rectangle.jpg);
	background-size: cover;
	background-position: center;
}

.play_win_earn_img {
	background-image: url(../LandingPage/images/playWinEarnBanner.png);
	background-size: cover;
	background-position: center;
	min-height: 32rem;
}

.hero_section--image {
	width: 100%;
	height: auto;
}
  

.connect-card h3{
  font-family: var(--font-titles);
}




/* Responsive css */

  
@media only screen and (min-width:1800px) and (max-width:2400px) {
    

	.section-connect p {
		text-align: center;
		font-size: 18px;
	}

	.section-how p {
		font-size: 18px;
		text-align: center;
	}

	.banner-content p {
		font-size: 18px;
		text-align: start !important;
	}
} 





  /*  */
@media only screen and (min-width:1400px) and (max-width:1800px) {
    

	.section-connect p {
		text-align: center;
		font-size: 18px;
	}

	.section-how p {
		font-size: 18px;
		text-align: center;
	}

	.banner-content p {
		font-size: 18px;
		text-align: start !important;
	}
}










  /*  */
@media only screen and (min-width:1200px) and (max-width:1399px) {
    

	.section-connect p {
		text-align: center;
		font-size: 18px;
	}

	.section-how p {
		font-size: 18px;
		text-align: center;
	}

	.banner-content p {
		font-size: 18px;
		text-align: start !important;
	}
}
  













  /*  */
@media only screen and (min-width:992px) and (max-width:1199px) {


	.section-connect p {
		text-align: center;
		font-size: 16px;
	}

	.section-how p {
		font-size: 16px;
		text-align: center;
	}

	.banner-content p {
		font-size: 18px;
		text-align: start !important;
	}
}









  /*  */
@media only screen and (min-width:768px) and (max-width:991px) {

	.banner-content p {
		font-size: 14px;
		text-align: start !important;
      /* margin-bottom: 30px; */
      /* text-align: center !important;     */;
	}

	.section-connect p {
		font-size: 14px;
      /* text-align: justify; */;
	}

	.section-how p {
		font-size: 14px;
      /* text-align: justify */;
	}
}







  /*  */
@media only screen and (max-width: 767px) {

	.hero-area {
		min-height: 500px;
		display: flex;
		align-items: center;
		flex-direction: column-reverse;
		justify-content: space-around;
	}

	.banner-content p {
		text-align: center;
	}

	.top-and-bottom::after {
		content: "";
		position: absolute;
		border: 1px solid #000;
		background: rgba(255, 255, 255, 0);
		box-shadow: 0px 2px 4px 0px #fff, 0px -2px 4px 0px #fff, 0px -4px 4px 0px rgba(255, 255, 255, 0), 0px 4px 4px 0px rgba(255, 255, 255, 0);
		width: 100%;
		height: 3px;
		bottom: -15px;
		left: 0;
		margin: 0px;
		z-index: 2;
	}
}










  /*  */
@media only screen and (max-width: 575px) {
    
	.hero-area {
		min-height: 600px;
		display: flex;
		align-items: center;
		flex-direction: column-reverse;
		justify-content: space-around;
	}

	.banner-content {
		text-align: center;
	}

	.banner-content h1 {
		font-size: 62px;
	}

	.banner-content p {
		font-size: 18px;
		margin-bottom: 30px;
		text-align: center !important;
	}

	.section-connect h1 {
		font-size: 42px;
	}

	.section-connect p {
		font-size: 18px;
		text-align: justify;
	}

	.connect-card .card-body h3 {
    font-size: 30px;
    line-height: 4;
}

	.blue-line::after {
		content: "";
		position: absolute;
		border: 1px solid #00c2ff;
		background: rgba(255, 255, 255, 0);
		box-shadow: 0px 2px 4px 0px #00c2ff, 0px -2px 4px 0px #00c2ff, 0px -4px 4px 0px rgba(0, 194, 255, 0.5), 0px 4px 4px 0px rgba(0, 194, 255, 0.5);
		width: 100%;
		height: 4px;
		bottom: 1.2rem;
		left: 0;
		z-index: 1;
	}

	.section-how h1 {
		font-size: 42px;
		text-align: center;
	}

	.section-how p {
		font-size: 18px;
		text-align: justify;
	}

	.section-connect {
		background-color: #000000;
		padding: 2rem 8px 3rem 8px;
	}

	.section-how {
		padding: 2rem 8px 3rem 8px;
	}

	.footer {
		padding: 30px 0;
  /* border-top: 1px solid #eee; */
  /* background-color: #000000; */;
	}

	.landing-authModule .btn1 {
		text-transform: uppercase;
		border-radius: 4px;
		font-size: 16px;
		font-family: var(--font-body-bold);
		font-weight: normal;
		letter-spacing: 0.1px;
		padding: 0 1.25rem;
		height: 3.5625rem;
		line-height: 3.4375rem;
		transition: all 0.3s;
	}

	.top-and-bottom {
		position: relative;
		left: 0px;
		width: 93%;
	}
}

.connect-card .card-body h3 {
	font-size: 30px;
	line-height: 3;
}

.header-nav:hover {
    background-color: var(--color-black) !important;
    color: var(--accent-color) !important;
}





  /*  */